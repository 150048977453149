
/**
 * @name: 销售管理-挂账客户管理
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-挂账客户管理
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IHangAccountCustomer, IHangAccountCustomerParams} from "@/apis/sale/hang/types";
import {ISystemAccount} from "@/apis/systemManage/account/types";
import {
  hangAccountCustomerCreateApi,
  hangAccountCustomerModifyApi,
  hangAccountCustomerQueryApi,
  hangAccountCustomerRemoveApi
} from "@/apis/sale/hang";
import {checkPhone} from "@/constants/validators";

@Component({})
export default class saleHang extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IHangAccountCustomerParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IHangAccountCustomer> = {}
  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    addTitle: '新增挂账客户',
    editTitle: '编辑挂账客户',
    menu: true,
    editBtn: false,
    delBtn: true,
    dialogWidth: '600px',
    labelWidth: '180px',
    column: [
      {
        label: "姓名",
        prop: "customerName",
        align: 'center',
        search: true,
        maxlength: 30,
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        span: 24
      },
      {
        label: "手机号",
        prop: "customerPhone",
        align: 'center',
        span: 24,
        rules: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {trigger: 'blur', validator: checkPhone}
        ],
      },
      {
        label: "挂账金额上限",
        prop: "price",
        align: 'center',
        width: 300,
        span: 24,
        slot: true,
        maxlength: 6,
        rules: [
          {required: true, message: '请输挂账金额上限', trigger: 'blur'},
          {
            trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
              if (!/^[1-9][0-9]*$/.test(value)) {
                return callback(new Error('请输入正整数'))
              }
              callback()
            }
          }
        ],
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        search:true,
        slot: true,
        value: 2,
        type: "switch",	//状态:1=启用 2=禁用
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 24,
        rules: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ]
      },
    ]
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ISystemAccount, val: number) {
    //@ts-ignore
    hangAccountCustomerModifyApi({id: row.id, status: val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  async openAdd() {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }


  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    hangAccountCustomerCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 删除提交
   */
  submitRemove(form: any, done: any, loading: any) {
    hangAccountCustomerRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  /**
   * 显示 挂账金额上限 输入框
   */
  async showEditPrice(row: IHangAccountCustomer, index: number) {
    if(!this.$store.getters.hasPermission(`hangCustomer:update:price`)){
      return
    }
    await this.getList();
    setTimeout(() => {
      this.tableData[index].isEdit = true;
    }, 300)
  }

  /**
   * 挂账金额上限--提交修改
   */
  submitPrice(row: IHangAccountCustomer, index: number) {
    if (!row.price || (!/^[1-9][0-9]*$/.test(row.price))) {
      this.$message({
        type: 'error',
        message: '请输入挂账金额上限'
      });
      return
    }
    let obj: IHangAccountCustomer = row;
    hangAccountCustomerModifyApi(obj).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
      }
    })

  }

  getList() {
    hangAccountCustomerQueryApi(this.queryParam).then(e => {
      //@ts-ignore
      this.tableData = e.list.map((item: any) => {
        item.isEdit = false;
        item.price = parseInt(item.price);
        return item
      })
      this.tableTotal = e.total
    })
  }

  created() {
    this.getList()
  }
}
