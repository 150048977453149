/**
 * @name: 销售管理-挂账客户管理接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-挂账客户管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IHangAccountCustomer, IHangAccountCustomerParams} from "@/apis/sale/hang/types";

/**
 * 查询挂账客户列表
 * @param params 查询参数
 * @returns
 */
export const hangAccountCustomerQueryApi = (params: IHangAccountCustomerParams) => get<IPageRes<IHangAccountCustomer[]>>("/admin/hangAccountCustomer/queryByPage", params)
/**
 * 创建挂账客户
 * @param data
 * @returns
 */
export const hangAccountCustomerCreateApi = (data: IHangAccountCustomer) => postJ("/admin/hangAccountCustomer/create", data)
/**
 * 编辑挂账客户文件
 * @param data
 * @returns
 */
export const hangAccountCustomerModifyApi = (data: IHangAccountCustomer) => postJ("/admin/hangAccountCustomer/modify", data)

/**
 * 获取挂账客户删除
 * @param id
 * @returns
 */
export const hangAccountCustomerRemoveApi = (id: string) => get<IHangAccountCustomer>("/admin/hangAccountCustomer/remove/" + id)
/**
 * 获取挂账客户详情
 * @param id
 * @returns
 */
export const hangAccountCustomerDetailApi = (id: string) => get<IHangAccountCustomer>("/admin/hangAccountCustomer/detail/" + id)
